@font-face {
  font-style: normal;
  src: url("../res/fonts/Montserrat-Light.ttf");
  font-family: "Montserrat";
  font-weight: 300;
}
@font-face {
  font-style: normal;
  src: url("../res/fonts/Montserrat-Regular.ttf");
  font-family: "Montserrat";
  font-weight: 400;
}
@font-face {
  font-style: normal;
  src: url("../res/fonts/Montserrat-Medium.ttf");
  font-family: "Montserrat";
  font-weight: 500;
}
@font-face {
  font-style: normal;
  src: url("../res/fonts/Montserrat-SemiBold.ttf");
  font-family: "Montserrat";
  font-weight: 600;
}
@font-face {
  font-style: normal;
  src: url("../res/fonts/Montserrat-ExtraBold.ttf");
  font-family: "Montserrat";
  font-weight: 800;
}
@font-face {
  font-style: normal;
  src: url("../res/fonts/Montserrat-Black.ttf");
  font-family: "Montserrat";
  font-weight: 900;
}
@font-face {
  font-style: italic;
  src: url("../res/fonts/Montserrat-ExtraLightItalic.ttf");
  font-family: "Montserrat";
  font-weight: 200;
}
@font-face {
  font-style: italic;
  src: url("../res/fonts/Montserrat-ExtraBoldItalic.ttf");
  font-family: "Montserrat";
  font-weight: 800;
}
@font-face {
  font-style: italic;
  src: url("../res/fonts/Montserrat-BlackItalic.ttf");
  font-family: "Montserrat";
  font-weight: 900;
}
